import { ThemeState } from '@jb/theme';
import { themeLocalStorageKey } from './theme.config';

export function ThemeInitializer(defaultState: () => ThemeState): () => ThemeState {
  return () => {
    try {
      const stringifiedThemeState = sessionStorage.getItem(themeLocalStorageKey);
      if (stringifiedThemeState !== null) {
        const themeState = JSON.parse(stringifiedThemeState) as ThemeState;
        themeState.loaded = true;
        return themeState;
      }
    } catch (e) {
      console.error(e);
    }
    return defaultState();
  };
}
