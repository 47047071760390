import { Inject, ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MaterialCssVarsModule, MaterialCssVarsService } from 'angular-material-css-vars';
import { ReactiveComponentModule } from '@ngrx/component';

// import { SelectComponent } from './select/select.component';
import { ThemeLightSwitchComponent } from './light-switch/light-switch.component';
import { ThemeState, ThemeStore } from './theme.store';
import { defaultThemeInitializerFactory } from './theme.initializer';
import { THEME_INITIALIZER, THEME_INITIAL_STATE, THEME_SAVER } from './theme.tokens';
// import { ThemeConfigComponent } from './config/config.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { ThemeConfigComponent } from './config/config.component';
import { defaultThemeSaverFactory } from './theme.saver';
import { defaultInitialStateFactory } from './theme.init';

export const defaultProviders: Provider[] = [
  {
    provide: THEME_INITIALIZER,
    useFactory: defaultThemeInitializerFactory,
    deps: [MaterialCssVarsService],
  },
  {
    provide: THEME_SAVER,
    useFactory: defaultThemeSaverFactory,
  },
  {
    provide: THEME_INITIAL_STATE,
    useFactory: defaultInitialStateFactory,
  },
];

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    ColorPickerModule,
    ReactiveComponentModule,
    MaterialCssVarsModule.forRoot(),
  ],
  declarations: [ThemeLightSwitchComponent, ThemeConfigComponent],
  exports: [ThemeLightSwitchComponent, ThemeConfigComponent],
  providers: defaultProviders,
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [ThemeStore],
    };
  }

  constructor(private themeStore: ThemeStore, @Inject(THEME_SAVER) themeSaver: (state: ThemeState) => void) {
    this.themeStore.state$.subscribe((themeState: ThemeState) => {
      themeSaver(themeState);
    });
  }
}
