import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  OnDemandPreloadService,
  OnDemandPreloadStrategy,
  PreloadFunctionToken,
  preloadStarter,
} from './core/routing-strategies/onDemandPreloading.strategy';

const routes: Routes = [
  {
    path: 'about',
    loadChildren: () => import('./modules/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'work',
    loadChildren: () => import('./modules/work/work.module').then((m) => m.WorkPageModule),
    data: {
      preload: true,
      preloadDelayInSeconds: 1,
    },
  },
  {
    path: 'code',
    loadChildren: () => import('./modules/code/code.module').then((m) => m.CodePageModule),
    data: {
      preload: true,
      preloadDelayInSeconds: 2,
    },
  },
  {
    path: 'theme',
    loadChildren: () => import('./modules/theme/theme.module').then((m) => m.ThemePageModule),
    data: {
      preload: true,
      preloadDelayInSeconds: 4,
    },
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/about',
  },
  {
    path: '**',
    redirectTo: 'about',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: OnDemandPreloadStrategy,
    }),
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: PreloadFunctionToken,
      useFactory: preloadStarter,
      deps: [OnDemandPreloadService],
    },
  ],
})
export class AppRoutingModule {}
