import { Theme, ThemeState } from '@jb/theme';
import { HueValue } from 'angular-material-css-vars';
import { DarkModeService } from './theme.service';

export function initThemeState(darkService: DarkModeService) {
  return () => {
    const defaultColorThresHold: HueValue = '500';
    const partialInitState: Partial<Theme> = {
      contrastColorThresHoldPrimary: defaultColorThresHold,
      contrastColorThresholdAccent: defaultColorThresHold,
      contrastColorThresholdWarn: defaultColorThresHold,

      // other theme settings
      alternativeColorAlgorithm: false,
      autoContrastEnabled: true,
    };
    const warnColor = '#ff0000';

    return {
      themes: [
        // deep-purple & amber
        {
          ...partialInitState,
          ...{
            primaryColor: '#673ab7',
            accentColor: '#ffc107',
            warnColor: warnColor,
          },
        },

        // indigo pink
        {
          ...partialInitState,
          ...{
            primaryColor: '#3f51b5',
            accentColor: '#e91e63',
            warnColor: warnColor,
          },
        },

        // indigo pink
        {
          ...partialInitState,
          ...{
            primaryColor: '#607d8b',
            accentColor: '#e91e63',
            warnColor: warnColor,
          },
        },
        {
          ...partialInitState,
          ...{
            primaryColor: '#9c27b0',
            accentColor: '#4caf50',
            warnColor: warnColor,
          },
        },
      ],
      isDark: darkService.prefersDarkMode,
      selectedIndex: 0,
      loaded: false,
    } as ThemeState;
  };
}
