import { ThemeState } from '@jb/theme';
import { themeLocalStorageKey } from './theme.config';

export function ThemeSaver(): (themeState: ThemeState) => void {
  return (themeState: ThemeState) => {
    try {
      const stringifiedThemeState = JSON.stringify(themeState);
      sessionStorage.setItem(themeLocalStorageKey, stringifiedThemeState);
    } catch (e) {
      console.error(e);
    }
  };
}
