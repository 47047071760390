import { Route } from '@angular/compiler/src/core';
import { Injectable, InjectionToken } from '@angular/core';
import { PreloadingStrategy } from '@angular/router';
import { EMPTY, Observable, Subject } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export type Preload = (routePath: string) => void;
export const PreloadFunctionToken = new InjectionToken<(routePath: string) => void>('PreloadFunctionToken');
export const preloadStarter: (s: OnDemandPreloadService) => Preload = (s: OnDemandPreloadService) => {
  return (routePath: string) => {
    s.startPreload(routePath);
  };
};

export class OnDemandPreloadOptions {
  constructor(public routePath: string, public preload = true) {}
}

@Injectable({ providedIn: 'root' })
export class OnDemandPreloadService {
  private subject = new Subject<OnDemandPreloadOptions>();
  state = this.subject.asObservable();

  startPreload(routePath: string) {
    const message = new OnDemandPreloadOptions(routePath, true);
    this.subject.next(message);
  }
}

interface OnDemandRoute {
  data?: {
    preload?: boolean | undefined;
  };
  path: string;
}

@Injectable({ providedIn: 'root', deps: [OnDemandPreloadService] })
export class OnDemandPreloadStrategy implements PreloadingStrategy {
  private preloadOnDemand$: Observable<OnDemandPreloadOptions>;

  constructor(private preloadOnDemandService: OnDemandPreloadService) {
    this.preloadOnDemand$ = this.preloadOnDemandService.state;
  }

  preload(route: Route, load: () => Observable<unknown>): Observable<unknown> {
    return this.preloadOnDemand$.pipe(
      mergeMap((preloadOptions) => {
        const shouldPreload = this.preloadCheck((route as unknown) as OnDemandRoute, preloadOptions);
        return shouldPreload ? load() : EMPTY;
      })
    );
  }

  private preloadCheck(route: OnDemandRoute, preloadOptions: OnDemandPreloadOptions) {
    return (
      route?.data &&
      route?.data?.preload &&
      [route?.path, '*'].includes(preloadOptions.routePath) &&
      preloadOptions.preload
    );
  }
}
