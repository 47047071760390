import { Component, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges, Inject } from '@angular/core';
import { SiteSection } from '../../sections';
import { Preload, PreloadFunctionToken } from '../routing-strategies/onDemandPreloading.strategy';

@Component({
  selector: 'jb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnChanges {
  @Input() sections: SiteSection[];
  sectionKeys: string[];

  constructor(@Inject(PreloadFunctionToken) public preload: Preload) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.sections) {
      this.sectionKeys = Object.keys(changes.sections.currentValue);
    }
  }
}
