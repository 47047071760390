import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';

import { HeaderComponent } from './header.component';
import { ThemeModule } from '@jb/theme';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [HeaderComponent],
  imports: [CommonModule, RouterModule, MatButtonModule, MatIconModule, ThemeModule],
  exports: [HeaderComponent],
})
export class HeaderModule {}
