export interface SiteSection {
  name: string;
  summary: string;
}

const about = 'about';
const work = 'work';
const code = 'code';

const ucFirst = (str: string): string => {
  const lowerCaseStr = str.toLowerCase();
  return lowerCaseStr.charAt(0).toUpperCase() + lowerCaseStr.slice(1);
};

export const SECTIONS: { [key: string]: SiteSection } = {
  [about]: {
    name: ucFirst(about),
    summary: 'The Component Dev Kit (CDK) is a set of behavior primitives for building UI components',
  },
  [work]: {
    name: ucFirst(work),
    summary: 'Angular Material offers a wide variety of UI',
  },
  [code]: {
    name: ucFirst(code),
    summary: 'The Component Dev Kit (CDK) is a set of behavior primitives for building UI components',
  },
};
