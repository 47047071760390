import { MaterialCssVarsService } from 'angular-material-css-vars';
import { ThemeState } from './theme.store';

export function defaultThemeInitializerFactory(materialCssVarsService: MaterialCssVarsService): () => ThemeState {
  return () => {
    return {
      themes: [
        {
          // color
          primaryColor: materialCssVarsService.primary,
          accentColor: materialCssVarsService.accent,
          warnColor: materialCssVarsService.warn,

          // contrast thresholds
          contrastColorThresHoldPrimary: materialCssVarsService.contrastColorThresholdPrimary,
          contrastColorThresholdAccent: materialCssVarsService.contrastColorThresholdAccent,
          contrastColorThresholdWarn: materialCssVarsService.contrastColorThresholdWarn,

          // other theme settings
          alternativeColorAlgorithm: materialCssVarsService.cfg.isAlternativeColorAlgorithm,
          autoContrastEnabled: materialCssVarsService.isAutoContrast,
        },
      ],
      isDark: false,
      selectedIndex: 0,
      loaded: false,
    };
  };
}
