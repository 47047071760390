import { Component, ChangeDetectionStrategy, ɵmarkDirty as markDirty } from '@angular/core';
import { ThemeStore } from '../theme.store';

@Component({
  selector: 'jb-theme-light-switch',
  templateUrl: './light-switch.component.html',
  styleUrls: ['./light-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThemeLightSwitchComponent {
  readonly isDark$ = this.themeStore.selectIsDark;
  constructor(private themeStore: ThemeStore) {}

  toggleDarkMode() {
    this.themeStore.toggleDarkMode();
    markDirty(this);
  }
}
