import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  ɵmarkDirty as markDirty,
} from '@angular/core';
import { HueValue } from 'angular-material-css-vars';
import { Theme } from '../theme';
import { ThemeStore } from '../theme.store';

@Component({
  selector: 'jb-theme-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThemeConfigComponent implements AfterViewInit {
  readonly theme$ = this.themeStore.selectCurrentTheme;

  hues = [
    { value: '50', viewValue: '50' },
    { value: '100', viewValue: '100' },
    { value: '200', viewValue: '200' },
    { value: '300', viewValue: '300' },
    { value: '400', viewValue: '400' },
    { value: '500', viewValue: '500' },
    { value: '600', viewValue: '600' },
    { value: '700', viewValue: '700' },
    { value: '800', viewValue: '800' },
    { value: '900', viewValue: '900' },
    { value: 'A100', viewValue: 'A100' },
    { value: 'A200', viewValue: 'A200' },
    { value: 'A400', viewValue: 'A400' },
    { value: 'A700', viewValue: 'A700' },
  ];

  private markDirty = () => markDirty(this);

  constructor(private themeStore: ThemeStore) {}

  ngAfterViewInit() {
    setTimeout(this.markDirty);
  }

  changePrimaryColor(hex: string) {
    this.themeStore.updateTheme({
      primaryColor: hex,
    });
  }

  changeAccentColor(hex: string) {
    this.themeStore.updateTheme({
      accentColor: hex,
    });
  }

  changeWarnColor(hex: string) {
    this.themeStore.updateTheme({
      warnColor: hex,
    });
  }

  changeAutoContrast(enabled: boolean) {
    this.themeStore.updateTheme({
      autoContrastEnabled: enabled,
    });
  }

  changeContrastColorThresHoldPrimary(threshold: HueValue) {
    this.themeStore.updateTheme({
      contrastColorThresHoldPrimary: threshold,
    });
    this.markDirty();
  }

  changeContrastColorThresholdAccent(threshold: HueValue) {
    this.themeStore.updateTheme({
      contrastColorThresholdAccent: threshold,
    });
    this.markDirty();
  }

  changeContrastColorThresholdWarn(threshold: HueValue) {
    this.themeStore.updateTheme({
      contrastColorThresholdWarn: threshold,
    });
    this.markDirty();
  }

  changeDarkMode(enabled: boolean) {
    this.themeStore.toggleDarkMode(enabled);
  }

  changeTraditionalColor(enabled: boolean) {
    this.themeStore.updateTheme({
      alternativeColorAlgorithm: enabled,
    });
  }
}
