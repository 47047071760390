import { MediaMatcher } from '@angular/cdk/layout';
import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class DarkModeService implements OnDestroy {
  private _prefersDarkModeQueryListener: () => void;
  private preferDarkModeQuery: MediaQueryList;
  private _toggleDarkMode = new Subject<boolean>();

  constructor(private mediaMatcher: MediaMatcher) {
    this.initWatcher();
  }

  startWatching() {
    if (this.preferDarkModeQuery) {
      this.preferDarkModeQuery.addEventListener('change', this._prefersDarkModeQueryListener);
    }
  }

  stopWatching() {
    if (this.preferDarkModeQuery) {
      this.preferDarkModeQuery.removeEventListener('change', this._prefersDarkModeQueryListener);
    }
  }

  initWatcher() {
    this.preferDarkModeQuery = this.mediaMatcher.matchMedia('(prefers-color-scheme: dark)');

    // watching darkmode
    this._prefersDarkModeQueryListener = () => {
      this._toggleDarkMode.next(this.preferDarkModeQuery.matches);
    };
  }

  get prefersDarkMode(): boolean {
    return this.preferDarkModeQuery.matches;
  }

  get toggleDarkMode$() {
    return this._toggleDarkMode.asObservable();
  }

  ngOnDestroy() {
    this.stopWatching();
  }
}
