import { Inject, Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { MaterialCssVarsService } from 'angular-material-css-vars';
import { Observable } from 'rxjs';
import { Theme, ThemeWithDark } from './theme';
import { auditTime, tap } from 'rxjs/operators';
import { THEME_INITIALIZER, THEME_INITIAL_STATE } from './theme.tokens';

export interface ThemeState {
  themes: Theme[];
  isDark: boolean;
  selectedIndex: number;
  loaded?: boolean;
}

@Injectable()
export class ThemeStore extends ComponentStore<ThemeState> {
  private readonly defaultTheme;

  constructor(
    private materialCssVarsService: MaterialCssVarsService,
    @Inject(THEME_INITIALIZER) themeInitializer: () => ThemeState,
    @Inject(THEME_INITIAL_STATE) private initialState: () => ThemeState
  ) {
    super(themeInitializer());
    this.defaultTheme = {
      primaryColor: materialCssVarsService.primary,
      accentColor: materialCssVarsService.accent,
      warnColor: materialCssVarsService.warn,

      // contrast thresholds
      contrastColorThresHoldPrimary: materialCssVarsService.contrastColorThresholdPrimary,
      contrastColorThresholdAccent: materialCssVarsService.contrastColorThresholdAccent,
      contrastColorThresholdWarn: materialCssVarsService.contrastColorThresholdWarn,

      // other theme settings
      alternativeColorAlgorithm: materialCssVarsService.cfg.isAlternativeColorAlgorithm,
      autoContrastEnabled: materialCssVarsService.isAutoContrast,
    };
    this.applyTheme();
  }

  readonly selectCurrentTheme = this.select((state) => {
    return {
      ...state.themes[state.selectedIndex],
      ...{ isDark: state.isDark },
    } as ThemeWithDark;
  });

  readonly selectThemes = this.select((state) => {
    return state.themes;
  });

  readonly selectIsDark = this.select((state) => {
    return state.isDark;
  });

  /**
   * Updaters
   */
  private readonly _resetState = this.updater(() => {
    return this.initialState();
  });

  private readonly _addTheme = this.updater((state: ThemeState, theme: Theme) => {
    const themes = [...state.themes, ...[theme]];
    return {
      ...state,
      ...{ themes: themes },
    };
  });

  private readonly _removeTheme = this.updater((state: ThemeState, index: number) => {
    const themes = [...state.themes.slice(0, index), ...state.themes.slice(index + 1)];
    return {
      ...state,
      ...{ themes: themes },
    };
  });

  private readonly _updateSelectedTheme = this.updater((state: ThemeState, theme: Theme) => {
    const themes = [...state.themes];
    themes[state.selectedIndex] = {
      ...themes[state.selectedIndex],
      ...theme,
    };

    return {
      ...state,
      ...{ themes: themes },
    };
  });

  private readonly _updateSelectedThemeIndex = this.updater((state: ThemeState, index: number) => {
    return {
      ...state,
      selectedIndex: index,
    };
  });

  private readonly _updateDarkMode = this.updater((state: ThemeState, isDark: boolean) => {
    return {
      ...state,
      isDark: isDark,
    };
  });

  private readonly applyThemeDelayed = this.effect((origin$: Observable<void>) =>
    origin$.pipe(
      auditTime(50),
      tap(() => this.applyTheme())
    )
  );

  private readonly applyTheme = this.effect((origin$: Observable<void>) =>
    origin$.pipe(
      tap(() => {
        const state = this.get();
        const theme = state.themes[state.selectedIndex];

        // update material css vars
        this.materialCssVarsService.setPrimaryColor(theme.primaryColor);
        this.materialCssVarsService.setAccentColor(theme.accentColor);
        this.materialCssVarsService.setWarnColor(theme.warnColor);

        this.materialCssVarsService.setContrastColorThresholdPrimary(theme.contrastColorThresHoldPrimary);
        this.materialCssVarsService.setContrastColorThresholdAccent(theme.contrastColorThresholdAccent);
        this.materialCssVarsService.setContrastColorThresholdWarn(theme.contrastColorThresholdWarn);

        this.materialCssVarsService.setAlternativeColorAlgorithm(theme.alternativeColorAlgorithm);
        this.materialCssVarsService.setAutoContrastEnabled(theme.autoContrastEnabled);

        // update dark setting
        this.materialCssVarsService.setDarkTheme(state.isDark);
      })
    )
  );

  /**
   * Actions/Effects
   */
  readonly resetState = this.effect((origin$: Observable<void>) =>
    origin$.pipe(
      tap(() => {
        this._resetState();
        this.applyTheme();
      })
    )
  );

  readonly addTheme = this.effect((origin$: Observable<void>) =>
    origin$.pipe(
      tap(() => {
        this._addTheme({ ...this.defaultTheme });
      })
    )
  );

  readonly removeTheme = this.effect((origin$: Observable<number>) =>
    origin$.pipe(
      tap((index: number) => {
        const state = this.get();
        if (state.selectedIndex !== index) {
          this._removeTheme(index);
        }
      })
    )
  );

  readonly updateTheme = this.effect((origin$: Observable<Partial<Theme>>) =>
    origin$.pipe(
      tap((theme: Theme) => {
        // update the theme in state
        this._updateSelectedTheme(theme);

        // apply the theme
        this.applyThemeDelayed();
      })
    )
  );

  readonly updateSelectedTheme = this.effect((origin$: Observable<number>) =>
    origin$.pipe(
      tap((selectedIndex: number) => {
        // get current state
        const state = this.get();
        if (selectedIndex < state.themes.length && state.selectedIndex !== selectedIndex) {
          this._updateSelectedThemeIndex(selectedIndex);
          this.applyThemeDelayed();
        }
      })
    )
  );

  private readonly _toggleDarkMode = this.effect((origin$: Observable<boolean | undefined>) =>
    origin$.pipe(
      tap((isDark: boolean | undefined) => {
        if (isDark === undefined) {
          isDark = !this.get().isDark;
        }

        // update the theme in state
        this._updateDarkMode(isDark);
        this.materialCssVarsService.setDarkTheme(isDark);
      })
    )
  );

  toggleDarkMode(isDark?: boolean) {
    return this._toggleDarkMode(isDark);
  }
}
