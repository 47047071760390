import { HueValue } from 'angular-material-css-vars';
import { Theme } from '.';
import { ThemeState } from './theme.store';

export function defaultInitialStateFactory(): () => ThemeState {
  return () => {
    const defaultColorThresHold: HueValue = '500';
    const partialInitState: Partial<Theme> = {
      contrastColorThresHoldPrimary: defaultColorThresHold,
      contrastColorThresholdAccent: defaultColorThresHold,
      contrastColorThresholdWarn: defaultColorThresHold,

      // other theme settings
      alternativeColorAlgorithm: false,
      autoContrastEnabled: true,
    };
    const warnColor = '#ff0000';
    return {
      themes: [
        // deep-purple & amber
        {
          ...partialInitState,
          ...{
            primaryColor: '#924141',
            accentColor: '#74b346',
            warnColor: warnColor,
          },
        },
      ],
      isDark: false,
      selectedIndex: 0,
      loaded: false,
    } as ThemeState;
  };
}
