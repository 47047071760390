import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderModule } from './header/header.module';
import { ThemeModule, ThemeStore, THEME_INITIALIZER, THEME_INITIAL_STATE, THEME_SAVER } from '@jb/theme';
import { ThemeInitializer } from './theme/theme.initializer';
import { ThemeSaver } from './theme/theme.saver';
import { initThemeState } from './theme/theme.init';
import { DarkModeService } from './theme/theme.service';

const providers: Provider[] = [
  {
    provide: THEME_INITIAL_STATE,
    useFactory: initThemeState,
    deps: [DarkModeService],
  },
  {
    provide: THEME_INITIALIZER,
    useFactory: ThemeInitializer,
    deps: [THEME_INITIAL_STATE],
  },
  {
    provide: THEME_SAVER,
    useFactory: ThemeSaver,
  },
  DarkModeService,
];

@NgModule({
  declarations: [],
  imports: [CommonModule, HeaderModule, ThemeModule.forRoot()],
  exports: [HeaderModule],
  providers: providers,
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [...providers, ...[ThemeStore]],
    };
  }

  constructor(private darkService: DarkModeService, private themeStore: ThemeStore) {
    this.darkService.toggleDarkMode$.subscribe((toggledDark) => {
      this.themeStore.toggleDarkMode(toggledDark);
    });
    this.darkService.startWatching();
  }
}
