<!-- navbar -->
<nav class="navbar-header" aria-label="Top Toolbar">
  <!-- logo -->
  <a mat-button routerLink="/" aria-label="Angular Material">
    <span class="logo"></span>
    <span>Jelle Bruisten</span>
  </a>

  <!-- menu links-->
  <a
    mat-button
    class="navbar-hide-small navbar-link"
    *ngFor="let key of sectionKeys"
    [routerLink]="key"
    (mouseover)="preload(key)"
  >
    {{ sections[key].name }}
  </a>

  <!-- spacer -->
  <div class="flex-spacer"></div>

  <!-- github/social links -->
  <jb-theme-light-switch></jb-theme-light-switch>

  <button mat-icon-button [routerLink]="'/theme'" (mouseover)="preload('theme')">
    <mat-icon>palette</mat-icon>
  </button>

  <a mat-button href="#" aria-label="GitHub Profile">
    <span class="github-logo" src="src/assets/img/github-circle-white-transparent.svg" alt="angular"></span>
    <span class="navbar-hide-small">GitHub</span>
  </a>
</nav>

<!-- navbar for smaller screens -->
<nav class="navbar navbar-show-small" aria-label="Section Nav Bar">
  <a mat-button class="navbar-link" *ngFor="let key of sectionKeys" [routerLink]="key">
    {{ sections[key].name }}
  </a>
</nav>
