import { Component, OnInit, ɵmarkDirty as markDirty } from '@angular/core';
import { SECTIONS } from './sections';

@Component({
  selector: 'jb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  readonly sections = SECTIONS;

  ngOnInit(): void {
    // setTimeout(() => {
    markDirty(this);
    // });
  }

  routeActivated() {
    markDirty(this);
  }
}
