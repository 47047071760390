<ng-container *ngrxLet="theme$ as theme">
  <div class="config-cards">
    <mat-card class="mat-elevation-z3">
      <mat-card-content>
        <h2>Primary color</h2>
        <div
          class="color-picker-el"
          (colorPickerChange)="changePrimaryColor($event)"
          [colorPicker]="theme.primaryColor"
          [cpAlphaChannel]="'disabled'"
          [cpDialogDisplay]="'inline'"
          [cpOKButton]="false"
          [cpOutputFormat]="'hex'"
          [cpToggle]="true"
          [style.background]="theme.primaryColor"
        ></div>
      </mat-card-content>
    </mat-card>
    <mat-card class="mat-elevation-z3">
      <mat-card-content>
        <h2>Accent color</h2>
        <div
          class="color-picker-el"
          (colorPickerChange)="changeAccentColor($event)"
          [colorPicker]="theme.accentColor"
          [cpAlphaChannel]="'disabled'"
          [cpDialogDisplay]="'inline'"
          [cpOKButton]="false"
          [cpOutputFormat]="'hex'"
          [cpToggle]="true"
          [style.background]="theme.accentColor"
        ></div>
      </mat-card-content>
    </mat-card>

    <mat-card class="mat-elevation-z3">
      <mat-card-content>
        <h2>Warn color</h2>
        <div
          class="color-picker-el"
          (colorPickerChange)="changeWarnColor($event)"
          [colorPicker]="theme.warnColor"
          [cpAlphaChannel]="'disabled'"
          [cpDialogDisplay]="'inline'"
          [cpOKButton]="false"
          [cpOutputFormat]="'hex'"
          [cpToggle]="true"
          [style.background]="theme.warnColor"
        ></div>
      </mat-card-content>
    </mat-card>

    <!-- <mat-card class="mat-elevation-z3">
      <mat-card-content class="contrast">
        <h2>Contrast Color</h2>

        <mat-slide-toggle
          (change)="changeAutoContrast(!theme.autoContrastEnabled)"
          [checked]="theme.autoContrastEnabled">Auto Contrast Colors
        </mat-slide-toggle>

        <mat-form-field>
          <mat-select
            (selectionChange)="changeContrastColorThresHoldPrimary($event.value)"
            [disabled]="theme.autoContrastEnabled"
            [value]="theme.contrastColorThresHoldPrimary"
            placeholder="Threshold for primary contrast color">
            <mat-option
              *ngFor="let hue of hues"
              [value]="hue.value">
              {{hue.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <br>
        <br>

        <mat-form-field>
          <mat-select
            (selectionChange)="changeContrastColorThresholdAccent($event.value)"
            [disabled]="theme.autoContrastEnabled"
            [value]="theme.contrastColorThresholdAccent"
            placeholder="Threshold for Accent contrast color">
            <mat-option
              *ngFor="let hue of hues"
              [value]="hue.value">
              {{hue.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <br>
        <br>

        <mat-form-field>
          <mat-select
            (selectionChange)="changeContrastColorThresholdWarn($event.value)"
            [disabled]="theme.autoContrastEnabled"
            [value]="theme.contrastColorThresholdWarn"
            placeholder="Threshold for Warn contrast color">
            <mat-option
              *ngFor="let hue of hues"
              [value]="hue.value">
              {{hue.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-card-content>
    </mat-card> -->

    <mat-card class="mat-elevation-z3">
      <mat-card-content class="other">
        <h2>Dark/Light</h2>
        <mat-slide-toggle (change)="changeDarkMode(!theme.isDark)" [checked]="theme.isDark">
          Dark Theme Enabled
        </mat-slide-toggle>

        <h2>Color Algorithm</h2>
        <mat-slide-toggle
          (change)="changeTraditionalColor(!theme.alternativeColorAlgorithm)"
          [checked]="theme.alternativeColorAlgorithm"
        >
          {{ theme.alternativeColorAlgorithm ? 'Alternative' : 'Constantin (default)' }}
        </mat-slide-toggle>
      </mat-card-content>
    </mat-card>
  </div>

  <mat-card class="mat-elevation-z3">
    <mat-card-content>
      <h2>Palette</h2>

      <div class="palette mat-elevation-z5">
        <div class="bg-50">
          <div>50</div>
        </div>
        <div class="bg-100">
          <div>100</div>
        </div>
        <div class="bg-200">
          <div>200</div>
        </div>
        <div class="bg-300">
          <div>300</div>
        </div>
        <div class="bg-400">
          <div>400</div>
        </div>
        <div class="bg-500">
          <div>
            primary
            <br />
            500
          </div>
        </div>
        <div class="bg-600">
          <div>600</div>
        </div>
        <div class="bg-700">
          <div>700</div>
        </div>
        <div class="bg-800">
          <div>800</div>
        </div>
        <div class="bg-900">
          <div>900</div>
        </div>
      </div>

      <div class="palette mat-elevation-z5">
        <div class="bga-50">
          <div>50</div>
        </div>
        <div class="bga-100">
          <div>100</div>
        </div>
        <div class="bga-200">
          <div>200</div>
        </div>
        <div class="bga-300">
          <div>300</div>
        </div>
        <div class="bga-400">
          <div>400</div>
        </div>
        <div class="bga-500">
          <div>
            primary
            <br />
            500
          </div>
        </div>
        <div class="bga-600">
          <div>600</div>
        </div>
        <div class="bga-700">
          <div>700</div>
        </div>
        <div class="bga-800">
          <div>800</div>
        </div>
        <div class="bga-900">
          <div>900</div>
        </div>
      </div>

      <div class="palette mat-elevation-z5">
        <div class="bgw-50">
          <div>50</div>
        </div>
        <div class="bgw-100">
          <div>100</div>
        </div>
        <div class="bgw-200">
          <div>200</div>
        </div>
        <div class="bgw-300">
          <div>300</div>
        </div>
        <div class="bgw-400">
          <div>400</div>
        </div>
        <div class="bgw-500">
          <div>
            primary
            <br />
            500
          </div>
        </div>
        <div class="bgw-600">
          <div>600</div>
        </div>
        <div class="bgw-700">
          <div>700</div>
        </div>
        <div class="bgw-800">
          <div>800</div>
        </div>
        <div class="bgw-900">
          <div>900</div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</ng-container>
